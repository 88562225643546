
















































import { Component, Vue, Provide } from "vue-property-decorator";
import { Form as ElForm } from "element-ui";
import { UserModule } from "@/store/modules/user";
import { setToken } from "@/utils/cookies";

@Component({
  components: {
    ElForm,
  },
})
export default class Login extends Vue {
  // 装饰器，相当于 data
  @Provide() isLoading: Boolean = false;
  @Provide() rememberPwd: Boolean = false;
  @Provide()
  loginForm = {
    email: "",
    pwd: "",
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWRtaW4iLCJlbWFpbCI6ImJvbm5pZS5xaW5AbW9iaWxlbm93Z3JvdXAuY29tIiwiYWRtaW5JZCI6ImQ3ZmFkZWQxLTRhNTQtNDc4Yy1iNWYxLTNkNDRiOTAzOGE0ZCIsImNyZWF0ZWRBdCI6IjE2NTg5ODkyNjQ1NjYiLCJpYXQiOjE2NTg5ODkyNjQsImV4cCI6MTY1OTAzMjQ2NH0.S5yXOWDJceNBg_ShD-gnHtyoBflwIXozlU_gH0G5O-o",
  };
  validateEmail = (rule: any, value: string, callback: Function) => {
    var reg = new RegExp(
      "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
    );
    if (!value.trim() || !reg.test(value)) {
      callback(new Error("请输入正确的邮箱"));
    } else {
      callback();
    }
  };

  validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < 4) {
      callback(new Error("密码不能少于4位"));
    } else {
      callback();
    }
  };
  @Provide()
  rules = {
    email: [
      {
        required: true,
        message: "请输入邮箱",
        trigger: "blur",
      },
      {
        required: true,
        trigger: "blur",
        validator: this.validateEmail,
      },
      {
        required: true,
        trigger: "change",
        validator: this.validateEmail,
      },
    ],
    pwd: [
      {
        required: true,
        message: "请输入密码",
        trigger: "blur",
      },
      {
        required: true,
        trigger: "blur",
        validator: this.validatePassword,
      },
      {
        required: true,
        trigger: "change",
        validator: this.validatePassword,
      },
    ],
  };

  goToPwdInput() {
    (this.$refs["pwd"] as HTMLElement).focus();
  }

  onLogin() {
    var data = {
      wineries: [
        {
          id: "7b069b74-9be5-458a-8551-c532746dcfc0",
          name: "夏桐酒庄",
          address: "上海黄浦区城区IAPM",
          navigationAddress: null,
          isMain: true,
          roles: [
            {
              name: "admin",
              label: "Admin",
              // name: "popupstore_manager",
              // label: "Pop-up Store Manage",
              assignedAt: "2022-03-11T06:40:44.576Z",
            },
          ],
        },
      ],
      email: "remi.bartel@mobilenowgroup.com",
      id: "8baab1db-ef8b-46a4-9f48-445ddbfcddcc",
      name: "Rémi Bartel",
      token: this.loginForm.token,
      sessionStart: "1647586135639",
    };
  const sss= atob(this.loginForm.token.split('.')[1])
  console.log(sss)
  console.log('sss')
    setToken(`Bearer ${this.loginForm.token}`);
    this.$message.success("登录成功");
    UserModule.RECORD_USERINFO_FOR_TEST(data);
    // UserModule.FetchVipLevels();
    this.$router.push("/");
    return;
  }
}
